<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-account-hard-hat"
          inline
          class="px-5 py-3"
          style="min-height: 300px"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Códigos da Distribuidora :: {{ company.name }}
            </div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
              >
                Aqui você pode informar ao sistema quais são os códigos da
                distribuidora {{ company.name }}.
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Código DIST (SIG-R)"
                outlined
                maxlength="10"
                v-model="codigo_dist_sigr"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Código DIST (SARI)"
                outlined
                maxlength="10"
                v-model="agente"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Código interno na distribuidora"
                outlined
                maxlength="10"
                v-model="codigo"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                min-width="100"
                style="float: right"
                class="mt-n5 mr-0"
                @click="save()"
                :disabled="btnSaveDisabled"
                :loading="loading"
              >
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyService from '@/services/CompanyService';
import AgenteService from '@/services/AgenteService';
import routes from '@/store/modules/routes';

export default {
  name: 'Agentes',
  data: () => ({
    company: { name: '' },
    codigo: '',
    agente: '',
    codigo_dist_sigr: '',
    loading: false
  }),
  mounted() {
    this.getCompany(this.$route.params.id);
    this.getAgentes(this.$route.params.id);
  },
  computed: {
    btnSaveDisabled() {
      return !this.agente.length;
    }
  },
  methods: {
    getAgentes(id) {
      AgenteService.getAgenteSelected(id)
        .then(({ data }) => {
          this.codigo = data.codigo;
          this.agente = data.agente;
          this.codigo_dist_sigr = data.codigo_dist_sigr;
        })
        .catch(() =>
          this.$toast.error('Erro ao buscar os dados do agente.', '', {
            position: 'topRight'
          })
        );
    },
    getCompany(id) {
      CompanyService.getCompanyById(id)
        .then((response) => (this.company.name = response.name))
        .catch(() =>
          this.$toast.error('Erro ao buscar os dados da empresa.', '', {
            position: 'topRight'
          })
        );
    },
    save() {
      this.loading = true;
      const data = {
        empresa: this.$route.params.id,
        codigo: this.codigo,
        agente: this.agente,
        codigo_dist_sigr: this.codigo_dist_sigr
      };
      AgenteService.save(data)
        .then(() => {
          this.$toast.success('Agentes configurados com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['EMPRESAS_LISTAR']
          });
        })
        .catch(() =>
          this.$toast.error('Erro ao salvar o agente para a empresa.', '', {
            position: 'topRight'
          })
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>
